import React, { useState } from 'react';
import { useSelector } from 'store/rootReducer';
import Signup from './SignUp';
import Confirm from './Confirm';

export const SignUpAndConfirm: React.FC = () => {
  const sidebarLocation = useSelector((state) => state.ui.sidebarLocation);
  const [password, setPassword] = useState('');

  if (sidebarLocation === 'signup')
    return <Signup password={password} setPassword={setPassword} />;
  // if (sidebarLocation === 'confirm')
  return <Confirm password={password} />;
};

export default SignUpAndConfirm;
