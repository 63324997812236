import {
  TOGGLE_LOGIN_SIDEBAR,
  NAVIGATE_SIDEBAR,
  SET_TYPED_EMAIL,
} from './actionTypes';
import { sidebarLocations } from './reducers';

export type toggleSidebarType = () => {
  type: typeof TOGGLE_LOGIN_SIDEBAR;
};

export const toggleSidebar: toggleSidebarType = () => ({
  type: TOGGLE_LOGIN_SIDEBAR,
});

export type navigateSidebarType = (
  location: sidebarLocations
) => {
  location: sidebarLocations;
  type: typeof NAVIGATE_SIDEBAR;
};

export const navigateSidebar: navigateSidebarType = (location) => ({
  location,
  type: NAVIGATE_SIDEBAR,
});

export type setTypedEmailType = (
  email: string
) => {
  email: string;
  type: typeof SET_TYPED_EMAIL;
};

export const setTypedEmail: setTypedEmailType = (email) => ({
  email,
  type: SET_TYPED_EMAIL,
});
