import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import urlSlug from 'url-slug';
import { useIntl } from 'react-intl';
import CreateOrganisation from './CreateOrganisation';
import { createUserOrganisation } from 'store/user/actions';
import { navigateSidebar } from 'store/ui/actions';

export const CreateOrganisationContainer = () => {
  const dispatch = useDispatch();
  const [orgName, setOrgName] = useState('');
  const [subDomain, setSubDomain] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [manualSubdomain, setManualSubdomain] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (manualSubdomain) return;
    setSubDomain(urlSlug(orgName));
  }, [orgName, manualSubdomain]);

  const doCreateOrganisation = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(createUserOrganisation(orgName, subDomain));
      setLoading(false);
      dispatch(navigateSidebar('listOrgs'));
    } catch (error) {
      console.error(error);
      setError(formatMessage({ id: 'genericError' }));
      setLoading(false);
    }
  };

  return (
    <CreateOrganisation
      doCreateOrganisation={doCreateOrganisation}
      loading={loading}
      setManualSubdomain={setManualSubdomain}
      setSubDomain={setSubDomain}
      subDomain={subDomain}
      setOrgName={setOrgName}
      orgName={orgName}
      error={error}
    />
  );
};

export default CreateOrganisationContainer;
