import { SET_USER_ATTRIBUTES } from './actionTypes';
import { setUserAttributesType } from './actions';
import cleanUserAttributes from 'doctry-shared-js/src/utils/cleanUserAttributes';

export interface userState extends user {
  authenticating: boolean;
  signedIn: boolean;
  organisations: {
    fetched?: boolean;
    list: Organisation[];
    loading?: boolean;
  };
}

const initialState: userState = {
  authenticating: false,
  signedIn: false,
  organisations: {
    fetched: false,
    list: [],
    loading: false,
  },
};

export const reducer = (
  state: userState = initialState,
  action: ReturnType<setUserAttributesType>
): userState => {
  switch (action.type) {
    case SET_USER_ATTRIBUTES:
      return { ...state, ...cleanUserAttributes(action.userAttributes) };
    default:
      return state;
  }
};

export default reducer;
