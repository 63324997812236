import styled from '@emotion/styled';

export const Form = styled.form``;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

export const FormButtons = styled.div`
  display: flex;

  ${(props) => `${props.theme.breakpoints.up('sm')} {
    align-items: center;
    justify-content: flex-end;
    
    > * {
      align-self: auto !important;
      margin-left: ${props.theme.spacing(1)}px;
    }
  }`}

  ${(props) => `${props.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    align-items: flex-end;
    > * {
      align-self: auto !important;
      margin-bottom: ${props.theme.spacing(1)}px;
    }
  }`}
`;
