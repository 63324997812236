import {
  TOGGLE_LOGIN_SIDEBAR,
  NAVIGATE_SIDEBAR,
  SET_TYPED_EMAIL,
} from './actionTypes';
import {
  toggleSidebarType,
  navigateSidebarType,
  setTypedEmailType,
} from './actions';

export type sidebarLocations =
  | 'signup'
  | 'signin'
  | 'confirm'
  | 'lostPassword'
  | 'listOrgs'
  | 'createOrg';

export interface UIState {
  sidebarOpen: boolean;
  sidebarLocation: sidebarLocations;
  typedEmail?: string;
}

const initialState: UIState = {
  sidebarOpen: false,
  sidebarLocation: 'signin',
};

export const reducer = (
  state: UIState = initialState,
  action:
    | ReturnType<toggleSidebarType>
    | ReturnType<navigateSidebarType>
    | ReturnType<setTypedEmailType>
): UIState => {
  switch (action.type) {
    case TOGGLE_LOGIN_SIDEBAR:
      return { ...state, sidebarOpen: !state.sidebarOpen };
    case NAVIGATE_SIDEBAR:
      return { ...state, sidebarLocation: action.location };
    case SET_TYPED_EMAIL:
      return { ...state, typedEmail: action.email };
    default:
      return state;
  }
};

export default reducer;
