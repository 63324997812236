import { SET_USER_ATTRIBUTES } from './actionTypes';
import { userState } from './reducers';
import { AppThunk } from '../types';
import { GET_USER_ORGANISATIONS } from 'doctry-shared-js/src/gql/queries/organisation';
import { gqlClient } from '../store';
import { Auth } from 'aws-amplify';
import { CREATE_ORGANISATION } from 'doctry-shared-js/src/gql/mutations/organisation';

export type setUserAttributesType = (
  userAttributes: Partial<userState>
) => {
  type: typeof SET_USER_ATTRIBUTES;
  userAttributes: Partial<userState>;
};

export const setUserAttributes: setUserAttributesType = (userAttributes) => ({
  type: SET_USER_ATTRIBUTES,
  userAttributes,
});

export const getUserOrganisation = (): AppThunk => async (
  dispatch,
  getState
) => {
  if (getState().user.organisations.fetched) return;
  try {
    dispatch(setUserAttributes({ organisations: { loading: true, list: [] } }));
    const {
      data: { getUserOrganisations },
    } = await gqlClient.query<{
      getUserOrganisations: Organisation[];
    }>({
      query: GET_USER_ORGANISATIONS,
    });

    dispatch(
      setUserAttributes({
        organisations: { loading: false, list: getUserOrganisations },
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentAuthenticatedUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserAttributes({ authenticating: true }));
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      dispatch(setUserAttributes({ ...user.attributes, signedIn: true }));
    } catch (error) {}
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(setUserAttributes({ authenticating: false }));
  }
};

export const signIn = (email: string, password: string): AppThunk => async (
  dispatch
) => {
  dispatch(setUserAttributes({ authenticating: true }));
  const user = await Auth.signIn({
    username: email,
    password,
    validationData: { organisationId: 'senaste' },
  });
  dispatch(
    setUserAttributes({
      ...user.attributes,
      authenticating: false,
      signedIn: true,
    })
  );
};

export const signUp = (email: string, password: string): AppThunk => async (
  dispatch
) => {
  dispatch(setUserAttributes({ authenticating: true }));
  await Auth.signUp({
    username: email,
    password,
  });
  dispatch(setUserAttributes({ authenticating: false }));
};

export const confirm = (
  email: string,
  confirmCode: string,
  password?: string
): AppThunk => async (dispatch) => {
  await Auth.confirmSignUp(email, confirmCode);
  if (password) {
    await dispatch(signIn(email, password));
  }
};

export const createUserOrganisation = (
  name: string,
  subDomain: string
): AppThunk => async (dispatch, getState) => {
  const {
    //@ts-ignore
    data: { createOrganisation },
  } = await gqlClient.mutate<{
    createOrganisation: Organisation[];
  }>({
    mutation: CREATE_ORGANISATION,
    variables: {
      name: name,
      id: subDomain,
    },
  });
  const newOrgList = [
    ...getState().user.organisations.list,
    createOrganisation,
  ];
  dispatch(
    setUserAttributes({ organisations: { fetched: true, list: newOrgList } })
  );
};
