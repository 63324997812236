import React from 'react';
import { Typography, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import LaunchIcon from '@material-ui/icons/Launch';
import styled from '@emotion/styled';

const Container = styled.div`
  & > a {
    margin-bottom: ${(props) => props.theme.spacing(2)}px !important;
  }
`;

interface SignedInProps {
  organisations: Organisation[];
  goToCreateOrg: () => void;
  loading: boolean;
}

export const OrgList: React.FC<SignedInProps> = ({
  organisations,
  goToCreateOrg,
  loading,
}) => {
  return (
    <Container>
      <Typography gutterBottom variant="h4">
        Dina kliniker
      </Typography>
      {loading && (
        <>
          <Skeleton variant="text" height="100" />
          <Skeleton variant="text" height="100" />
        </>
      )}

      {organisations.length > 0 ? (
        <>
          <Typography gutterBottom>
            Här kan du se alla organisationer du är medlem i
          </Typography>
          {organisations.map((organisation) => (
            <Button
              key={organisation.id}
              fullWidth
              href={`https://${organisation.id}.${process.env.REACT_APP_HOST}`}
              variant="outlined"
              endIcon={<LaunchIcon />}
              color="primary"
            >
              {organisation.name}
            </Button>
          ))}
        </>
      ) : (
        <Typography gutterBottom>
          Du är inte med i några kliniker ännu
        </Typography>
      )}

      <Button
        fullWidth
        variant="contained"
        endIcon={<AddIcon />}
        color="primary"
        onClick={goToCreateOrg}
      >
        Skapa organisation
      </Button>
    </Container>
  );
};

export default OrgList;
