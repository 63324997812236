import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import uiReducer from './ui/reducers';
import userReducer from './user/reducers';

const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector = createSelectorHook<RootState>();

export default rootReducer;
