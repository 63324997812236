import React, { useEffect, useCallback, memo } from 'react';
import { toggleSidebar, navigateSidebar } from 'store/ui/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/rootReducer';
import Sidebar from './Sidebar';

export const SidebarContainer: React.FC = memo(() => {
  const sidebarOpen = useSelector((state) => state.ui.sidebarOpen);
  const sidebarLocation = useSelector((state) => state.ui.sidebarLocation);
  const signedIn = useSelector((state) => state.user.signedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (signedIn) {
      dispatch(navigateSidebar('listOrgs'));
    }
  }, [signedIn, dispatch]);

  const onClose = useCallback(() => {
    dispatch(toggleSidebar());
  }, []);

  return (
    <Sidebar
      open={sidebarOpen}
      onClose={onClose}
      sidebarLocation={sidebarLocation}
    />
  );
});

export default SidebarContainer;
