import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import store from 'store';
import { Auth } from 'aws-amplify';
import awsconfig from 'doctry-shared-js/src/aws/config';

Auth.configure(awsconfig);

(async () => {
  const cache = createIntlCache();
  const locale = 'sv-SE';
  const messages = await import(`doctry-shared-js/src/lang/${locale}.json`);

  const intl = createIntl(
    {
      defaultLocale: 'sv-SE',
      locale: locale,
      messages: messages,
    },
    cache
  );

  ReactDOM.render(
    <RawIntlProvider value={intl}>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </RawIntlProvider>,
    document.getElementById('root')
  );
  reportWebVitals();
})();
