import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/rootReducer';
import { getUserOrganisation } from 'store/user/actions';
import { navigateSidebar } from 'store/ui/actions';
import OrgList from './OrgList';
import { Auth } from 'aws-amplify';
import awsconfig from 'doctry-shared-js/src/aws/config';

export type sections = 'main' | 'createOrganisation';

export const OrgListContainer = () => {
  const dispatch = useDispatch();
  const organisations = useSelector((state) => state.user.organisations.list);
  const loading = useSelector((state) => state.user.organisations.loading);

  const openCreateOrg = () => dispatch(navigateSidebar('createOrg'));

  Auth.configure({
    ...awsconfig,
    clientMetadata: { organisationId: 'senaste' },
  });

  useEffect(() => {
    dispatch(getUserOrganisation());
  }, []);

  return (
    <OrgList
      organisations={organisations}
      goToCreateOrg={openCreateOrg}
      loading={loading!}
    />
  );
};

export default OrgListContainer;
