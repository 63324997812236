import React, { useState, useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import Button from 'components/UI/Button';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FormRow, FormButtons } from 'components/UI/Form';
import { useSelector } from 'store/rootReducer';
import { navigateSidebar, setTypedEmail } from 'store/ui/actions';
import { signIn } from 'store/user/actions';

export const Signin: React.FC = () => {
  const { formatMessage } = useIntl();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const email = useSelector((state) => state.ui.typedEmail);
  const loading = useSelector((state) => state.user.authenticating);

  const Signin = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        await dispatch(signIn(email!, password));
        setSuccess(true);
      } catch (error) {
        try {
          setError(formatMessage({ id: error.code }));
        } catch (error) {
          setError(formatMessage({ id: 'genericError' }));
        }
      }
    },
    [email, formatMessage, password, dispatch]
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Logga in
      </Typography>

      {error && <Typography color="error">{error}</Typography>}
      <form autoComplete="off" onSubmit={Signin}>
        <FormRow>
          <TextField
            fullWidth
            required
            type="email"
            color="primary"
            label={formatMessage({ id: 'email' })}
            variant="outlined"
            value={email}
            onChange={(e) => dispatch(setTypedEmail(e.target.value))}
          />
        </FormRow>
        <FormRow>
          <TextField
            fullWidth
            required
            label={formatMessage({ id: 'password' })}
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormRow>

        <FormButtons>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(navigateSidebar('signup'));
            }}
          >
            {formatMessage({ id: 'register' })}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(navigateSidebar('lostPassword'));
            }}
          >
            {formatMessage({ id: 'forgotPassword' })}
          </Button>
          <Button
            variant="contained"
            type="submit"
            loading={loading}
            success={success}
          >
            {formatMessage({ id: 'login' })}
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default Signin;
