import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { WithTheme } from '@material-ui/core';
import theme from 'doctry-shared-js/src/theme';
import Router from './Router';
import styled from '@emotion/styled';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from 'components/Sidebar';
import { useDispatch } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import publicClient from 'gql/publicClient';
import { getCurrentAuthenticatedUser } from 'store/user/actions';

const Root = styled.div<WithTheme>`
  *,
  > * {
    box-sizing: border-box;
  }
  min-height: 100vh;
`;

const App = () => {
  const dispatch = useDispatch();

  dispatch(getCurrentAuthenticatedUser());

  return (
    <Root theme={theme}>
      <ApolloProvider
        //@ts-ignore
        client={publicClient}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <EmotionThemeProvider theme={theme}>
            <Sidebar />
            <Router />
          </EmotionThemeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Root>
  );
};

export default App;
