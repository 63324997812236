import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import Button from 'components/UI/Button';
import { useIntl } from 'react-intl';
import { FormRow, FormButtons } from 'components/UI/Form';
import { sidebarLocations } from 'store/ui/reducers';

interface SignupProps {
  email?: string;
  setEmail: (email: React.ChangeEvent<HTMLTextAreaElement>) => void;
  password: string;
  setPassword: (password: React.ChangeEvent<HTMLTextAreaElement>) => void;
  navigate: (location: sidebarLocations) => void;
  onSubmit: (e: React.FormEvent) => void;
  loading: boolean;
  error: string;
  success: boolean;
}

export const SignUp: React.FC<SignupProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  navigate,
  onSubmit,
  loading,
  error,
  success,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Typography variant="h4">Registrera dig</Typography>

      {error && <Typography color="error">{error}</Typography>}
      {success && (
        <Typography>{formatMessage({ id: 'register.success' })}</Typography>
      )}
      <form autoComplete="off" onSubmit={onSubmit}>
        <FormRow>
          <TextField
            fullWidth
            required
            type="email"
            color="primary"
            label={formatMessage({ id: 'email' })}
            variant="outlined"
            value={email}
            onChange={setEmail}
          />
        </FormRow>
        <FormRow>
          <TextField
            fullWidth
            required
            label={formatMessage({ id: 'password' })}
            variant="outlined"
            type="password"
            value={password}
            onChange={setPassword}
          />
        </FormRow>

        <FormButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigate('signin');
            }}
          >
            {formatMessage({ id: 'back' })}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigate('confirm');
            }}
          >
            {formatMessage({ id: 'fillinConfirmationCode' })}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            loading={loading}
            success={success}
          >
            {formatMessage({ id: 'register' })}
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default SignUp;
