import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from 'assets/meddico.svg';

const StyledLogo = styled(Logo)`
  height: 3rem;
  animation: fade 1s infinite;
`;

const Root = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <Root>
      <StyledLogo />
    </Root>
  );
};

export default Loading;
