import React from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface DoctryButton extends ButtonProps {
  loading?: boolean;
  error?: boolean;
  success?: boolean;
}

const LoadingWrapper = styled.div`
  position: relative;
  align-self: stretch;
  display: inline-block;
`;

const Progress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

export const Button = ({
  loading,
  error,
  success,
  disabled,
  color = 'primary',
  disableElevation = true,
  ...rest
}: DoctryButton) => {
  const isDisabled = disabled || loading || success;
  return (
    <LoadingWrapper>
      <MuiButton
        disabled={isDisabled}
        disableElevation={disableElevation}
        color={color}
        {...rest}
      />
      {loading && <Progress size={24} />}
      {success && <SuccessIcon color="secondary" />}
    </LoadingWrapper>
  );
};

export default Button;
