import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { createPrivateClient } from 'doctry-shared-js/src/gql';
import { Auth } from 'aws-amplify';
import awsconfig from 'doctry-shared-js/src/aws/config';

Auth.configure({
  ...awsconfig,
  clientMetaData: { organisationId: 'senaste' },
  validationData: { organisationId: 'senaste' },
});
export const gqlClient = createPrivateClient(awsconfig, Auth);

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
