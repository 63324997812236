import React from 'react';
import {
  Typography,
  TextField,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import Button from 'components/UI/Button';
import { FormRow, FormButtons } from 'components/UI/Form';
import { useIntl } from 'react-intl';

export interface CreateOrganisationProps {
  doCreateOrganisation: (e: React.FormEvent) => void;
  setManualSubdomain: (setValue: boolean) => void;
  setSubDomain: (setValue: string) => void;
  setOrgName: (setValue: string) => void;
  subDomain: string;
  loading: boolean;
  orgName: string;
  error: string;
}

export const CreateOrganisation: React.FC<CreateOrganisationProps> = ({
  doCreateOrganisation,
  setManualSubdomain,
  setSubDomain,
  subDomain,
  setOrgName,
  loading,
  orgName,
  error,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {formatMessage({ id: 'createClinic' })}
      </Typography>
      <Typography>{formatMessage({ id: 'createorg.description' })}</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={doCreateOrganisation}>
        <FormRow>
          <TextField
            fullWidth
            required
            color="primary"
            label={'Företagsnamn'}
            variant="outlined"
            value={orgName}
            onChange={(e) => {
              setOrgName(e.target.value);
            }}
          />
        </FormRow>
        <FormRow>
          <FormControl fullWidth>
            <InputLabel required variant="outlined" htmlFor="subdomain">
              URL
            </InputLabel>
            <OutlinedInput
              id="subdomain"
              required
              color="primary"
              label="Subdomain"
              // variant="outlined"
              value={subDomain}
              onChange={(e) => {
                setManualSubdomain(true);
                setSubDomain(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">.doctry.se</InputAdornment>
              }
            />
          </FormControl>
        </FormRow>
        <FormButtons>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            loading={loading}
          >
            {formatMessage({ id: 'create' })}
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default CreateOrganisation;
