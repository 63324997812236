import React, { useState } from 'react';
import { useSelector } from 'store/rootReducer';
import { signUp } from 'store/user/actions';
import { useDispatch } from 'react-redux';
import { setTypedEmail, navigateSidebar } from 'store/ui/actions';
import { sidebarLocations } from 'store/ui/reducers';
import { useIntl } from 'react-intl';
import Signup from './SignUp';

interface SignupProps {
  password: string;
  setPassword: (password: string) => void;
}

export const SignUpContainer: React.FC<SignupProps> = ({
  password,
  setPassword,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.authenticating);
  const email = useSelector((state) => state.ui.typedEmail);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const SignUp = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await dispatch(signUp(email!, password));

      setSuccess(true);
    } catch (error) {
      console.log(error);
      try {
        setError(formatMessage({ id: error.code }));
      } catch (error) {
        setError(formatMessage({ id: 'genericError' }));
      }
    }
  };

  const setEmail = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(setTypedEmail(e.target.value));

  const setPasswordInternal = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setPassword(e.target.value);

  const navigateInternal = (location: sidebarLocations) =>
    dispatch(navigateSidebar(location));

  return (
    <Signup
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPasswordInternal}
      navigate={navigateInternal}
      onSubmit={SignUp}
      loading={loading}
      error={error}
      success={success}
    />
  );
};

export default SignUpContainer;
