import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadingPage from 'components/UI/LoadingPage';
// import { useLocation } from 'react-router-dom';

const LandingPage = lazy(() => import('components/LandingPage'));
const About = lazy(() => import('components/About'));

// const production = process.env.NODE_ENV === 'production' || true;

export const PageTracker: React.FC = ({ children }) => {
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   if (production) {
  //     gtag('event', 'page_view', {
  //       page_path: pathname,
  //       page_title: pathname,
  //     });
  //   }
  // }, [pathname]);

  return <>{children}</>;
};

const Router = () => {
  return (
    <BrowserRouter>
      <PageTracker>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/about" component={About} />
          </Switch>
        </Suspense>
      </PageTracker>
    </BrowserRouter>
  );
};

export default Router;
