import React, { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { Typography, TextField } from '@material-ui/core';
import Button from 'components/UI/Button';
import { useIntl } from 'react-intl';
import { useSelector } from 'store/rootReducer';
import { FormRow, FormButtons } from 'components/UI/Form';
import { useDispatch } from 'react-redux';
import { confirm } from 'store/user/actions';
import { navigateSidebar } from 'store/ui/actions';
import { sidebarLocations } from 'store/ui/reducers';

interface ConfirmProps {
  password?: string;
}

export const Confirm: React.FC<ConfirmProps> = ({ password }) => {
  const initialEmail = useSelector((state) => state.ui.typedEmail);
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState(initialEmail);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [confirmCode, setConfirmCode] = useState('');
  const dispatch = useDispatch();

  const confirmUser = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        await dispatch(confirm(email!, confirmCode, password));
        setSuccess(true);
      } catch (error) {
        try {
          setError(formatMessage({ id: error.code }));
        } catch (error) {
          setError(formatMessage({ id: 'genericError' }));
        }
      } finally {
        setLoading(false);
      }
    },
    [dispatch, confirmCode, email, formatMessage, password]
  );

  const navigateInternal = (location: sidebarLocations) =>
    dispatch(navigateSidebar(location));

  return (
    <>
      <Typography variant="h4">Bekräfta kod</Typography>

      {error && !success && <Typography color="error">{error}</Typography>}
      {success && !error && (
        <Typography color="secondary">
          {formatMessage({ id: 'confirmcodeSuccess' })}
        </Typography>
      )}
      <form autoComplete="off" onSubmit={confirmUser}>
        <FormRow>
          <TextField
            fullWidth
            required
            color="primary"
            label={formatMessage({ id: 'email' })}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            fullWidth
            required
            label={formatMessage({ id: 'confirmCode' })}
            variant="outlined"
            type="number"
            value={confirmCode}
            onChange={(e) => {
              setConfirmCode(e.target.value);
            }}
          />
        </FormRow>
        <FormButtons>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigateInternal('signup');
            }}
          >
            {formatMessage({ id: 'back' })}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            onClick={confirmUser}
            loading={loading}
            success={success}
          >
            {formatMessage({ id: 'confirm' })}
          </Button>
        </FormButtons>
      </form>
    </>
  );
};

export default Confirm;
