import { amplifyConfigType } from 'doctry-shared-js/src/types';

export const amplifyConfig: amplifyConfigType = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT!,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION!,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY!,
};

export default amplifyConfig;
