import React from 'react';
import { Drawer, Container } from '@material-ui/core';
import styled from '@emotion/styled';
import { sidebarLocations } from 'store/ui/reducers';
import Signin from './parts/SignIn';
import SignUpAndConfirm from './parts/SignupAndConfirm';
import OrgList from './parts/OrgList';
import CreateOrganisation from './parts/CreateOrganisation';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  sidebarLocation: sidebarLocations;
}

const StyledSidebar = styled(Drawer)`
  .MuiDrawer-paper {
    padding-top: ${(props) => props.theme.spacing(2)}px;
    width: 32rem;

    ${(props) => `${props.theme.breakpoints.down('sm')} {
      width: 100vw;
  }`}
  }
`;

export const Sidebar: React.FC<SidebarProps> = ({
  open,
  onClose,
  sidebarLocation,
}) => {
  return (
    <StyledSidebar anchor="right" open={open} onClose={onClose}>
      <Container>
        {sidebarLocation === 'signin' && <Signin />}
        {(sidebarLocation === 'signup' || sidebarLocation === 'confirm') && (
          <SignUpAndConfirm />
        )}
        {sidebarLocation === 'listOrgs' && <OrgList />}
        {sidebarLocation === 'createOrg' && <CreateOrganisation />}
      </Container>
    </StyledSidebar>
  );
};

export default Sidebar;
